import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Dashbot CEO Arte Merritt delivered the keynote address at `}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io"
      }}>{`SuperBot 2019`}</a>{`.`}</p>
    <p>{`He shares some of the more interesting insights Dashbot gathered by processing over 66 billion (and counting!) messages.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=7w69T3Ivz%5C_ADo"
      }}>{`https://www.youtube.com/watch?v=7w69T3Ivz\\_ADo`}</a>{` you know what the most common message a user writes to a Facebook chatbot or says to a Google Action? Do you know how frequently users interact with their Alexa or Google Home and what the more common use cases are? Do you know how startups and brands acquiring users for their voice skills? Or would you like to know why enterprises are building customer service chatbots? Find out answers to these and more in the video above!`}</p>
    <p>{`Arte covers best practices and insights across the following areas:`}</p>
    <ul>
      <li parentName="ul">{`Common messages in text based chatbots`}</li>
      <li parentName="ul">{`Common messages and interactions in voice`}</li>
      <li parentName="ul">{`Behaviors of Alexa and Google Home users`}</li>
      <li parentName="ul">{`Alexa skill ratings`}</li>
      <li parentName="ul">{`Customer service insights`}</li>
    </ul>
    <h2>{`Subscribe to our event mailing list`}</h2>
    <p>{`Thank you to everyone who participated in SuperBot and made it a huge success. We love bringing together people to share and learn from each other, so we regularly host meetups in New York City and San Francisco.`}</p>
    <p>{`If you’d like to hear more and be notified about Dashbot events, sign up for our mailing list here.`}</p>
    <p>{`{" "}`}</p>
    <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css"></link>
    <style type="text/css">
  {`
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    #mc_embed_signup .button {
        background: #ff6a51;
    }
    #mc_embed_signup .button:hover {
        background: #30aabd;
    }`}
    </style>
    <form action="https://dashbot.us13.list-manage.com/subscribe/post?u=a7c5d5ea7f4d59ddd76ed312c&id=e5438a1680" id="mc-embedded-subscribe-form" method="post" name="mc-embedded-subscribe-form" noValidate="" target="_blank">
\* indicates required  
      <label htmlFor="mce-EMAIL">Email Address \*  
      </label>  
      <input id="mce-EMAIL" name="EMAIL" type="email" value=""></input>  
      <label htmlFor="mce-FNAME">First Name \*</label>  
      <input id="mce-FNAME" name="FNAME" type="text" value=""></input>  
      <label htmlFor="mce-LNAME">Last Name \*</label>  
      <input id="mce-LNAME" name="LNAME" type="text" value=""></input>  
      <label htmlFor="mce-MMERGE3">Job Title </label>  
      <input id="mce-MMERGE3" name="MMERGE3" type="text" value=""></input>  
      <label htmlFor="mce-MMERGE4">Company </label>  
      <input id="mce-MMERGE4" name="MMERGE4" type="text" value=""></input>  
**Event Locations**
      <ul>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-0" name="group[12769][1]" type="checkbox" value="1"></input>
          <label htmlFor="mce-group[12769]-12769-0">San Francisco</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-1" name="group[12769][2]" type="checkbox" value="2"></input>
          <label htmlFor="mce-group[12769]-12769-1">New York</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-2" name="group[12769][4]" type="checkbox" value="4"></input>
          <label htmlFor="mce-group[12769]-12769-2">Superbot</label>
        </li>
      </ul>
      <input name="b_a7c5d5ea7f4d59ddd76ed312c_e5438a1680" tabIndex="-1" type="text" value=""></input>
      <input id="mc-embedded-subscribe" name="subscribe" type="submit" value="Subscribe"></input>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      